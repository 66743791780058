import thingshappen  from '../assets/thingshappen.png';
import contact  from '../assets/contact.png';
import logo  from '../assets/logo.png';
import services01  from '../assets/security.jpeg';
import services02  from '../assets/echosystem Growing.jpeg';
import services03  from '../assets/performance.jpeg';
import services04  from '../assets/restake.jpeg';
import services05  from '../assets/transparant.jpeg';
import services06  from '../assets/fee.jpeg';
import cosmosLogo from '../assets/atom.png';
import akashLogo from '../assets/akash.png';
import osmosisLogo from '../assets/osmo.png';
import planqLogo from '../assets/planq.png';
import celestiaLogo from '../assets/celestia.png';
import oraichainLogo from '../assets/oraichain.png';
import eigen from '../assets/eigen.jpeg';
import hero from '../assets/logo111.gif';

export default{
thingshappen,
contact,
logo,
services01,
services02,
services03,
services04,
services05,
services06,
atom: cosmosLogo,
akash: akashLogo,
osmosis: osmosisLogo,
planq: planqLogo,
celestia: celestiaLogo,
oraichain: oraichainLogo,
eigen,
hero,

};